* {
  animation: fade 0.25s ease-out;
}
.inputCodeContainer {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.banner2 {
  margin: 15px 0 0 0;
  width: 50vw;
  opacity: 0.3;
  color: white;
  overflow: hidden;
}
.joinText {
  color: white;
  margin: 10px 0 0 0;
  font-size: 14px;
}
.joinText a {
    color: #f56c64;
    font-weight: 600;
    transition: all 0.17s ease-in-out;
    font-size: 10px;
}
.joinText a:hover {
    color: #ffbebb;
}
.movedown {
    margin: 50px 0 0 0;
    transition: all 0.17s ease-out;
}
.FadeDown {
    animation: fadeDown 0.18s ease-out;
}

@keyframes fadeDown {
    from {
        opacity: 0;
        margin-top: -20px;
      }
      to {
        opacity: 1;
        margin-top: 5px;
      }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
