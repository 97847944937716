.DetailContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 530px;
  background: rgb(215, 214, 214);
  border-radius: 12px;
  box-shadow: 0 0 15px white;
  text-align: center;
  overflow: hidden;
  filter: brightness(100%);
  opacity: 0.65;
}
.trackTeam {
  display: flex;
  justify-content: center;
}
/* @keyframes stormCont {
  0% {
      opacity: 1;
  }
  10% {
    opacity:0.8;
  }
  20% {
    opacity:1;
  }
  50% {
    opacity:0.7;
  }
  70% {
    opacity:1;
  }
  80% {
    opacity:0.7;
  }
  90% {
    opacity:0.6;
  }
  100% {
    opacity:1;
  }
} */
.detailTitle {
  font-weight: 500;
  font-size: 16px;
}
.detailInfo {
  font-size: 16px;
}
.rowDetail {
  margin: 25px 0 0 0;
}
.back {
  position: absolute;
  width: 103%;
  display: flex;
  justify-content: space-between;
  left: -5px;
  top: 0px;
  /* padding: 2px; */
  z-index: 1;
}
.timestamp {
  margin: 5px 0 0 0;
  font-size: 12px;
}
.preventCopy {
  user-select: none;
}
.banner {
  position: absolute;
  bottom: 0;
}
.runnerText {
  font-size: 10px;
}
@media only screen and (max-width: 600px) {
  .runnerText {
    font-size: 8.5px;
  }
}

