.logo {
  position: absolute;
  top: 8px;
  left: calc(105px + 50px);
  height: 80px;
  filter: drop-shadow(0 0 4px rgb(157, 60, 60));
  animation: fade 0.5s ease-in-out;
  margin: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}
.fpt {
  position: absolute;
  top: 8px;
  left: 18px;
  height: 65px;
  filter: drop-shadow(0 0 4px rgb(157, 60, 60));
  animation: fade 0.75s ease-in-out;
  margin: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}
.LogoNTTContainer {
  display: flex;
  position: absolute;
  filter: drop-shadow(0 0 2px #fdb95e);
  animation: fade 0.75s ease-in-out;
}
.NTT {
  padding: 0 10px 0 20px;
}
.titleNTT {
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoNTT {
  height: 40px;
  margin: 0 8px 0 8px;
  padding: 10px 0 0 0;
}
.logoDVDH {
  height: 20px;
  margin: 5px 8px 0 8px;
  padding: 10px 0 0 0;
}
.Afprocado {
  margin: 15px 0 0 0;
  height: 10px;
  padding: 0;
}
@media only screen and (min-width: 1151px) {
  .LogoNTTContainer {
    right: 8px;
    top: 12px;
  }
  .DVDH {
    margin: 0 0 0 10px;
  }
}
@media only screen and (max-width: 1150px) {
  .logo {
    top: 7px;
    height: 60px;
    left: 112px;
  }
  .fpt {
    height: 48px;
    left: 5px;
  }
  .logoNTT {
    height: 30px;
  }
  .titleNTT {
    font-size: 8px;
  }
  .LogoNTTContainer {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 100%;
    /* overflow: hidden; */
    animation: move 25s linear infinite;
  }
  .NTT {
    min-width: 180px;
    margin: 0 0 15px 0;
    padding: 0;
    margin: 0;
  }
  .NTT:nth-child(2) {
    margin: 0 60px 0 30px;
  }
  .NTT:nth-child(3) {
    margin: 0 60px 0 0;
  }
  .DVDH {
    min-width: 200px;
    margin: 0 0 0 40px;
  }
  .logoDVDH {
    height: 16px;
    margin: 3px 8px 0 8px;
  }
  .Afprocado {
    height: 6px;
    padding: 0;
  }
}
@keyframes move {
  from {
    transform: translateX(35%);
  }
  to {
    transform: translateX(-300%);
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
